import React, { useEffect, useState } from 'react'
import PlayerInfo from './PlayerInfo';
import PressCards from './PressCards';
import MorePlayerInformation from './MorePlayerInformation';
import PlayerStrengthsAndWeaknesses from './PlayerStrengthsAndWeaknesses';
import LeagueStatistics from './LeagueStatistics';
import SimilarPlayers from './SimilarPlayers';
import { useParams } from 'react-router-dom';
import { apiService } from '../services/apiService';
import Loader from '../templates/Loader';
import Modal from '../templates/Modal';
import Breadcrumb from '../templates/Breadcrumb';
import { authenticationService } from '../services/authenticationService';
const DetailComponents = () => {

    const { uuid } = useParams();
    const [playerData, setPlayerData] = useState({});
    const [loadingData, setLoadingData] = useState(true);
    const [modalMessage, setModalMessage] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const onError = (message) => {
        
        setShowModal(true);
        setModalMessage(message)
    };

    useEffect(() => {
        setLoadingData(true);
        apiService.apiGet(process.env.REACT_APP_API_URL + `/player/${uuid}`)
            .then((data) => {
                setPlayerData(data);
                setLoadingData(false);
            },
                error => {
                    if ('401' === error.message) {
                        authenticationService.logout();
                    } else {
                        onError(error.message + ' error');
                    }                    
                })
    }, [uuid]);
    const breadcrumbItems = [
        { label: 'Home', to: '/' },
        { label: `${playerData.data?.first_name.split(' ')[0]} ${playerData.data?.last_name}` }
    ];
    return (
        <div>

            {showModal &&
                <Modal message={modalMessage} onClose={() => setShowModal(false)} />
            }
            {loadingData ? (
                <Loader />
            ) : (
                <>
                    <Breadcrumb items={breadcrumbItems} />
                    <div className="mb-5 mb-md-7">
                        <PlayerInfo playerinfo={playerData.data} insights={playerData?.insights}/>
                    </div>
                    <div className="mb-5 mb-md-7">
                        <PressCards playerNews={playerData?.news} />
                    </div>
                    <div className="mb-5 mb-md-7">
                        <MorePlayerInformation playerinfo={playerData.data} />
                    </div>
                    <div className="mb-5 mb-md-7">
                        <PlayerStrengthsAndWeaknesses data={playerData?.report} />
                    </div>
                    <div className="mb-5 mb-md-7">
                        <LeagueStatistics playerstats={playerData.data} stats={playerData.stats} />
                    </div>
                    <div className="mb-5 mb-md-7">
                        <SimilarPlayers onError={(message) => { setShowModal(true); setModalMessage(message) }} />
                    </div>
                </>
            )}
        </div>
    )
}

export default DetailComponents
