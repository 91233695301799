const HelperFunctions = () => {

    const kFormatter = (num) => {
        return Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'k' : Math.sign(num) * Math.abs(num)
    }
    const dateFormatter = (datestr) => {

        const suffix = {
            one: 'st',
            two: 'nd',
            few: 'rd',
            other: 'th',
        };

        var theDate = new Date(datestr);

        return new Intl.DateTimeFormat('en-GB', { day: 'numeric' }).format(theDate) + suffix[new Intl.PluralRules('en-GB', { type: 'ordinal' }).select(theDate.getDate())] + ' ' + new Intl.DateTimeFormat('en-GB', { month: 'long' }).format(theDate) + ' ' + theDate.getFullYear();
    }
    const ageFormatter = (dateOfBirth) => {
        const dob = new Date(dateOfBirth);
        const diffMs = Date.now() - dob.getTime();
        const ageDt = new Date(diffMs);
        return Math.abs(ageDt.getUTCFullYear() - 1970);
    };


    const statsFormatter = (stat) => {
        let sentence = stat.split(/(?=[A-Z])/).join(' ').toLowerCase();
        return sentence.charAt(0).toUpperCase() + sentence.slice(1); 
    };

    const stringTruncate = (str, n = 100) => {
        if (!str) return ''; // Return an empty string if str is undefined or null
        return (str.length > n) ? str.slice(0, n - 1) + '...' : str;
    };

    function formatValue(value) {
        if (value === undefined || value === null) {
            return 'N/A';
        }
        if (value === 0) {
            return '0';
        }
        if (typeof value === 'number') {
            return value.toLocaleString();
        }
        return value;
    }

    return { kFormatter, dateFormatter, stringTruncate, ageFormatter, statsFormatter, formatValue };

}
export default HelperFunctions;