import React from 'react'

export default function PlayerCardTypeahead ({ data })  {
    
    const { firstName, lastName , currentTeam, positions} = data;

    const firstName_extracted = firstName.split(' ')[0];

   
     return (        
        <div className='p-2half'>        
            <div className='d-flex flex-column align-items-start'>                
                <p className="d-block mb-0 fw-normal text-center small  text-decoration-none text-white">{firstName_extracted ||  "Luke"} <strong className="fw-semi-bold">{lastName || "Shaw"}</strong></p>
                { 0 < positions.length && <p className="d-block mb-0 extra-small text-neutral-gray-700 text-center">{positions[0].positionName}</p> }
                <p className="d-block mb-0 extra-small text-neutral-gray-700 text-center">{currentTeam}</p>
            </div>
        </div>       
    )
}
