import React from 'react';
import HelperFunctions from '../helpers/HelperFunctions';

export default function PlayerCard({ data = {}, aiinsights = false, includeteam = true, stats = [] }) {
    const { first_name, last_name, date_of_birth, image, positions = [] } = data;
    const fullName = `${first_name || ''} ${last_name || ''}`.trim();
   
    const { ageFormatter, statsFormatter } = HelperFunctions();

    const age = date_of_birth ? ageFormatter(date_of_birth) : '';   
    
    const team_image = data.team?.image || data.team_image;
    const team_name = data.team?.name || data.team || data.team_name;
    const first_name_extracted = first_name.split(' ')[0];

    if (data.position_name)
        positions.push({'name':data.position_name});


    return (
        <>
            <div className='h-100 d-flex flex-column justify-content-between align-items-center px-2half px-md-3 w-100'>
                <div className={`d-flex flex-row flex-md-column align-items-md-center w-100 mb-2 mb-md-0`}>
                    {image ?
                        <img className={`object-fit-cover disc-65 disc-md-9 rounded-circle bg-white mb-2 mb-md-3 me-md-0 me-2`} src={image} alt={fullName} />
                        :
                        <svg className='disc-65 disc-md-9 mb-0 mb-md-3 me-2 me-md-0' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 105 104">
                            <g clipPath="url(#a)">
                                <rect width="104" height="104" x=".8" fill="#3A3C43" rx="52" />
                                <g fill="#80909D" clipPath="url(#b)">
                                    <path d="M61.83 68.84H44.17A30.15 30.15 0 0 0 14 98.97c0 3.33 2.7 6.03 6.03 6.03h65.94A6.02 6.02 0 0 0 92 98.97a30.15 30.15 0 0 0-30.17-30.13Z" />
                                    <path d="M75.29 38.25A22.27 22.27 0 0 1 53 60.5a22.27 22.27 0 0 1-22.29-22.25A22.27 22.27 0 0 1 53 16c12.3 0 22.29 9.96 22.29 22.25Z" opacity=".4" />
                                </g>
                            </g>
                            <defs>
                                <clipPath id="a">
                                    <rect width="104" height="104" x=".8" fill="#fff" rx="52" />
                                </clipPath>
                                <clipPath id="b">
                                    <path fill="#fff" d="M14 16h78v89H14z" />
                                </clipPath>
                            </defs>
                        </svg>
                    }              
                    
                    <div className='d-flex flex-column align-items-start align-items-md-center '>
                        <p className="mb-0 large fw-normal text-center">
                            {first_name_extracted} <strong className="fw-semi-bold">{last_name}</strong>
                        </p>                      
                        <p className="mb-0 mb-md-3 small text-neutral-gray-700 text-md-center">{age}{0 < age && 0 < positions.length ? <>&nbsp;&middot;&nbsp;</> : <></>}{positions.map(position => position.name).join(' / ')}</p>
                        {
                            0 < stats.length &&
                            <div className='mb-3 px-6 px-md-3 d-flex flex-column align-items-start align-items-md-center  text-fluoro-green-600 '>
                                {stats.map((value, index) => (
                                    <>
                                        <p className='d-none d-md-block mb-0 small'>{Math.floor(value[1]).toLocaleString()} {statsFormatter(value[0])}</p>
                                        <p className='d-block d-md-none mb-0 extra-small'>{Math.floor(value[1])} {value[0]}</p>
                                    </>

                                ))
                                }
                            </div>
                        }
                    </div>                  
                </div>
                {aiinsights && (
                    <div className='d-flex align-items-start align-items-md-center text-fluoro-green-600 mb-2 mb-md-3 w-100 w-md-auto px-6 px-md-3'>
                        <div className='d-none d-md-block'>
                            <i className='fa fa-sparkles me-3'></i>
                        </div>
                        <div className='d-block d-md-none'>
                            <i className='fa fa-sparkles me-3 extra-small'></i>
                        </div>
                        <p className='d-none d-md-block mb-0 small'>First cap for national team at 18 years old</p>
                        <p className='d-block d-md-none mb-0 extra-small'>First cap for national team at 18 years old</p>
                    </div>
                )}
            </div>
            {includeteam && (
                <div className='bg-dark-mode-50 w-100 py-2 px-2half px-md-3 center-content rounded-2 rounded-top-start-0 rounded-top-end-0'>
                    {team_image ?
                        <img className="object-fit-cover disc-35 disc-md-4 rounded-circle me-2" src={team_image} alt={team_name || "Manchester United"} />
                        :
                        <svg className='me-2 disc-35 disc-md-4' width="35" height="34" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_1379_8063)">
                                <path d="M22.8528 6.31207L22.1875 4.7607C20.7682 4.25375 19.2839 4 17.799 4C16.3137 4 14.8278 4.2535 13.4085 4.7602L12.7473 6.31207L17.8 9.11164L22.8528 6.31207ZM9.20685 8.88262L7.52548 9.03191C5.7588 11.3044 4.80005 14.0999 4.80005 16.9792L4.80005 17C4.80005 17.1368 4.80269 17.2732 4.80692 17.4094L6.07391 18.5145L10.2989 14.5703L9.20685 8.88262ZM20.9891 21.0625L22.9442 15.0398L17.7543 11.3125L12.6559 15.0398L14.6272 21.0625H20.9891ZM30.8 16.9797C30.8 14.1004 29.8403 11.3023 28.0721 9.03242L26.4125 8.88313L25.3004 14.5727L29.5259 18.5168L30.7914 17.4103C30.7543 17.2082 30.8 17.1066 30.8 16.9797ZM7.42899 22.6723L7.05372 24.3206C8.75388 26.8145 11.2665 28.6406 14.1631 29.4851L15.6154 28.6223L13.1683 23.3766L7.42899 22.6723ZM22.4313 23.373L19.9842 28.6188L21.435 29.482C24.332 28.636 26.8432 26.8099 28.5443 24.3176L28.1696 22.6692L22.4313 23.373Z" fill="#77828B" />
                                <path opacity="0.4" d="M30.7899 17.4063C30.7166 19.8773 29.9357 22.2747 28.5413 24.3176L28.1665 22.6693L22.4282 23.3731L19.9811 28.6188L21.4141 29.4739C20.2218 29.8182 18.9934 29.9919 17.7655 29.9919C16.5589 29.9919 15.3529 29.8242 14.1813 29.486L15.6149 28.6197L13.1678 23.374L7.42947 22.6692L7.0542 24.3175C5.66025 22.2766 4.88076 19.8787 4.80713 17.4062L6.07412 18.5112L10.2991 14.567L9.20682 8.88266L7.52545 9.03195C9.03467 7.08754 11.0634 5.61082 13.3754 4.77242L13.3938 4.78605L12.7473 6.31211L17.8 9.11168L22.8528 6.31211L22.1875 4.76074C24.5143 5.59762 26.5547 7.0784 28.0731 9.03195L26.4125 8.88316L25.3004 14.5727L29.5259 18.5169L30.7899 17.4063ZM22.9442 15.0399L17.7543 11.3125L12.6559 15.0399L14.6272 21.0625H20.9891L22.9442 15.0399Z" fill="#77828B" />
                            </g>
                            <defs>
                                <clipPath id="clip0_1379_8063">
                                    <rect width="26" height="26" fill="white" transform="translate(4.80005 4)" />
                                </clipPath>
                            </defs>
                        </svg>
                    }
                    <p className="mb-0 small text-neutral-gray-700">{team_name || "Manchester United, England"}</p>                    
                </div>
            )}
        </>
    );
}
